import React from 'react';
import { WithWizard } from 'react-albus';
import Button from 'react-bootstrap/Button';
import {FormattedMessage} from 'react-intl';

const Navigation = () => (
  <WithWizard
    render={({ next, previous, step, steps }) => (
      <div className="example-buttons">
        {steps.indexOf(step) < steps.length - 1 && (
          <Button className="float-right my-3" type="button" id="nextButton" onClick={next}>
          <FormattedMessage id="Navigation.button-next"
                        defaultMessage="Next"/>
          </Button>
        )}

        {steps.indexOf(step) > 0 && (
          <Button className="btn-secondary my-3" type="button" id="prevButton" onClick={previous}>
          <FormattedMessage id="Navigation.button-previous"
                        defaultMessage="Previous"/>
          </Button>
        )}
      </div>
    )}
  />
);

export default Navigation;
