import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Wizard, Steps, Step } from 'react-albus';
import Navigation from './Navigation';
import InputTable from './AdminHelper'

import './App.css';
import {PuzzleTextArea, PegCheckbox, ColorSchemeSelector, OptionsComponent} from './editComponents.js';
import {TextPreview, ColoredTextPreview} from './colorSchemeGrid.js';
import {useIntl, injectIntl, FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import {defineMessages} from 'react-intl';
import TextToSVG from 'text-to-svg';
import wunderholzLogo from './WunderHolz_logo.jpg';
import beechWoodLogo from './beechwoodstory_logo.jpg'
import puzzleFont from './fonts/PuzzleFont.otf'
import $ from 'jquery';
window.$ = $;

class SVGPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textToSVG: '',
      svgPreviewString: "SVG Pnreview",
      downloadSVGString: "Download SVG for Cutting",
      cutSVG: '',
      cutOptions:     {x: 0, y: 0, fontSize: 390.942074933781264, kerning: true, anchor: 'top', attributes: {fill: 'none', stroke: 'red'}}
    }
    // plt zachowało kolor graweru
    // 517 px dla 10cm wysokosci, max 12 znakow
    // 320px dla 6.2cm wysokosci, max 20 znakow
    // 24.625 px mniej dla kazdego dodatkowego znaku
    // fontSize: 226.772
    this.handleDownloadSVGLinkClick = this.handleDownloadSVGLinkClick.bind(this);
  }

  handleDownloadSVGLinkClick(event) {
    var svg = null;

    if (event.target.id === "cutLink") {
      svg = document.querySelector("#cutSVG svg")
    }

    if (svg){
      //get svg source.
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(svg);

      //add name spaces.
      if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
          source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }
      if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
          source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }

      //add xml declaration
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

      //convert svg source to URI data scheme.
      var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

      //set url value to a element's href attribute.
      if (event.target.id === "cutLink") {
        document.getElementById("cutLink").href = url;
      }

    }
  }

  async componentDidMount() {
    let currentComponent = this;
    TextToSVG.load(puzzleFont, function(err, textToSVG) {
      if (err) {
            alert('Font could not be loaded: ' + err);
        }
        currentComponent.setState({
          textToSVG: textToSVG
        }, () => {
          currentComponent.updateAndNotify();
        })
    });
  }

  updateAndNotify = () => {
    if (this.state.textToSVG !== ''){
      // var moreNames = " CLARA LAYLA CALEB VALERIE SOFIA LILA LUCY ARLO LUIS ALFIE MIA NAOMI LEA LOUNA JOY NATHAN TABEA FREYA"
      // var alhabet = " A B C D E F G H I J K L M N O P Q R S T U V W X Y Z"
      this.setState({
        cutSVG: this.state.textToSVG.getSVG(this.props.puzzleText, this.state.cutOptions)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.puzzleText !== this.props.puzzleText) {
      this.updateAndNotify();
    }
  }

    render() {
      // if (this.props.currentStep !== 3) { // Prop: The current step
      //   return null
      // }
      return (
        <div>
        {/* <h1 className="header">{this.state.svgPreviewString}</h1> */}
        <div id="cutSVG"  style={{display: 'none'}} dangerouslySetInnerHTML={{ __html: this.state.cutSVG }} />
        <a id="cutLink" href="#" onClick={this.handleDownloadSVGLinkClick} download="cutText.svg">{this.state.downloadSVGString}</a>
        <br />
        </div>
      );
    }
}

class Summary extends React.Component {
  constructor(props) {
    super(props);
    const {intl} = this.props;
    this.state = {
      otherSchemeName: this.props.intl.formatMessage({id: "Summary.OtherColorSchemeName", defaultMessage: "Other"}),
      puzzleTextString: intl.formatMessage({id: "Summary.puzzleTextString", defaultMessage: "Puzzle Text:"}),
      colorSchemeString: intl.formatMessage({id: "Summary.colorSchemeString", defaultMessage: "Color Scheme:"}),
      woodTypeString: intl.formatMessage({id: "Summary.woodTypeString", defaultMessage: "Wood Type:"}),
      engravingString: intl.formatMessage({id: "Summary.engravingString", defaultMessage: "Engraving:"}),
      engravingTextString: intl.formatMessage({id: "Summary.engravingTextString", defaultMessage: "Text to engrave:"}),
      pegsString: intl.formatMessage({id: "Summary.pegsString", defaultMessage: "Pegs:"}),
      puzzleStandString: intl.formatMessage({id: "Summary.puzzleStandString", defaultMessage: "Puzzle Stand:"}),
      giftWrapString: intl.formatMessage({id: "Summary.giftWrapString", defaultMessage: "Gift Wrap:"}),
      letterCountString: intl.formatMessage({id: "Summary.letterCountString", defaultMessage: "Letter Count:"}),
      yesString: intl.formatMessage({id: "Summary.yesString", defaultMessage: "Yes"}),
      noString: intl.formatMessage({id: "Summary.noString", defaultMessage: "No"}),
      noneString: intl.formatMessage({id: "Summary.noneString", defaultMessage: "None"}),
      etsyAPIKey: intl.formatMessage({id: "Summary.etsyAPIKey", defaultMessage: "zt08j7vp7z5vqsd3hxvtz3op"}),
      etsyListingID: intl.formatMessage({id: "Summary.etsyListingID", defaultMessage: "896133143"}),
      etsyEngravingListingID: intl.formatMessage({id: "Summary.etsyEngravingListingID", defaultMessage: "863976417"}),
      engravingPriceEur: intl.formatMessage({id: "Summary.engravingPriceEur", defaultMessage: "0"}),
      etsyStandListingID: intl.formatMessage({id: "Summary.etsyStandListingID", defaultMessage: "850058604"}),
      standPriceEur: intl.formatMessage({id: "Summary.standPriceEur", defaultMessage: "0"}),
      etsyGiftWrapListingID: intl.formatMessage({id: "Summary.etsyGiftWrapListingID", defaultMessage: "896137755"}),
      giftWrapPriceEur: intl.formatMessage({id: "Summary.giftWrapPriceEur", defaultMessage: "0"}),
      data: [],
      etsyLetterCountPropertyID: intl.formatMessage({id: "Summary.etsyLetterCountPropertyID", defaultMessage: "514"}),
      etsyColorSchemePropertyID: intl.formatMessage({id: "Summary.etsyColorSchemePropertyID", defaultMessage: "513"}),
      responseJSON: null,
      isLoadingEtsyRedirect: false
    }
    this.handleProductRequest = this.handleProductRequest.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.findProperProductId = this.findProperProductId.bind(this);
    this.displayProductId = this.displayProductId.bind(this);

    this.countLetters = this.countLetters.bind(this);
    this.formatColorScheme = this.formatColorScheme.bind(this);

    this.summaryTextArray = this.summaryTextArray.bind(this);
  }

  countLetters () {
    var sum = 0;
    sum += this.props.puzzleText.replace(/\s/g, '').length;
    return sum;
  }

  originalProductPrice(priceObject) {
    // console.log('priceObject: ', priceObject)
    if (priceObject.hasOwnProperty('before_conversion')) {
      return parseInt(priceObject.before_conversion.amount);
    } else {
      return parseInt(priceObject.amount);
    }
  }

  formatColorScheme() {
    if (this.props.selectedColorScheme != "Andere") {
      return (this.props.selectedColorScheme);
    } else {
      console.log("Other Selected")
      console.log(this.props.colorSchemes["Andere"]);
      return (this.state.otherSchemeName + " => " + this.props.colorSchemes["Andere"].toString());
    }
  }

  handleProductRequest(){
    $.ajax({
      url: 'https://openapi.etsy.com/v2/listings/'+this.state.etsyListingID+'/inventory.js?api_key='+this.state.etsyAPIKey,
      dataType: 'jsonp',
      success: (data) => {
        this.setState({
          responseJSON: data.results
        })
      }
    })
    $.ajax({
      url: 'https://openapi.etsy.com/v2/listings/'+this.state.etsyEngravingListingID+'/inventory.js?api_key='+this.state.etsyAPIKey,
      dataType: 'jsonp',
      success: (data) => {
        var price = this.originalProductPrice(data.results.products[0].offerings[0].price)

        this.setState({
          engravingPriceEur: price
        })
      }
    })
    $.ajax({
      url: 'https://openapi.etsy.com/v2/listings/'+this.state.etsyStandListingID+'/inventory.js?api_key='+this.state.etsyAPIKey,
      dataType: 'jsonp',
      success: (data) => {
        var price = this.originalProductPrice(data.results.products[0].offerings[0].price)

        this.setState({
          standPriceEur: price
        })
      }
    })
    $.ajax({
      url: 'https://openapi.etsy.com/v2/listings/'+this.state.etsyGiftWrapListingID+'/inventory.js?api_key='+this.state.etsyAPIKey,
      dataType: 'jsonp',
      success: (data) => {
        var price = this.originalProductPrice(data.results.products[0].offerings[0].price)

        this.setState({
          giftWrapPriceEur: price
        })
      }
    })

  }

  handleButtonClick() {
    console.log('button_clicked')
    this.setState({
      isLoadingEtsyRedirect: true
    })
  }

  findProperProductId() {
    // go though each object in json
    var letterCountProp = this.countLetters();
    var withPegsProp = this.props.withPegs;
    var colorSchemeProp = this.props.selectedColorScheme;

    // based on chosen options, it selects the proper Ids of variations
    // Buy Peg Puzzle button
    // click on the peg buzzzle button leads to etsy authorization
    // once it is authorized, it adds the products
    // when the product is added, it redirects to etsy.com/cart, the added product should be displayed
    console.log("Letter count: " + letterCountProp + ", with pegs: " + withPegsProp + ", color schmeme: " + colorSchemeProp);
    if (this.state.responseJSON === null || (Array.isArray(this.state.responseJSON) && this.state.responseJSON.length)) {
      return null
    }
    // console.log(this.state.responseJSON)
    for (const product of this.state.responseJSON.products) {
      var variationMatchesLetterAndPegOption = false;
      var variationMatchesColor = false;
      // if property id is 513, check regex for number of letters
      console.log(product)
      for (const variation of product.property_values) {
        if (parseInt(variation.property_id) == this.state.etsyLetterCountPropertyID) {
          var letterCount = parseInt(variation.values[0].replace(/\D/g,''));
          if (letterCount == letterCountProp){
            variationMatchesLetterAndPegOption = true;
          }
        }

        if (parseInt(variation.property_id) == this.state.etsyColorSchemePropertyID) {

          // console.log(colorSchemeProp)
          if (parseInt(variation.values[0]) == colorSchemeProp) {
            variationMatchesColor = true;
          } else if (variation.values[0] == this.state.otherSchemeName && colorSchemeProp == 'Andere') {
            variationMatchesColor = true;
          }
        }
      }

      if (variationMatchesLetterAndPegOption && variationMatchesColor) {
        console.log("Variation matches options:");
        return product;
      }
    }
    return null;
  }

  copyToClipboard = (e) => {
    window.getSelection().selectAllChildren(
      document.getElementById("summaryTextDiv")
    );
    document.execCommand('copy');
    e.target.focus();
    console.log("copied text");
  };

  summaryTextArray () {
    var personalizationPuzzleText =  this.state.puzzleTextString + ' ' + this.props.puzzleText;
    var personalizationColorScheme = this.state.colorSchemeString + ' ' + this.formatColorScheme();
    var personalizationWoodType =  this.state.woodTypeString + ' ' + this.props.woodName;
    var personalizationEngrave =  this.state.engravingString + ' ' + (this.props.withEngravingOnTheBack ? this.state.yesString : this.state.noString);
    var personalizationEngraveText = this.state.engravingTextString + ' ' + (this.props.withEngravingOnTheBack ? this.props.textToEngrave : this.state.noneString);
    var personalizationPegs = this.state.pegsString + ' ' + (this.props.withPegs ? this.state.yesString : this.state.noString);
    var personalizationPuzzleStand =  this.state.puzzleStandString + ' ' + (this.props.withStandPuzzle ? this.state.yesString : this.state.noString);
    var personalizationGift =  this.state.giftWrapString + ' ' + (this.props.withGiftWrap ? this.state.yesString : this.state.noString);
    var personalizationLetterCount =  this.state.letterCountString + ' ' + this.countLetters();

    var personalizationText = [personalizationPuzzleText, personalizationColorScheme, personalizationWoodType,
                              personalizationEngrave, personalizationEngraveText, personalizationPegs, personalizationPuzzleStand,
                              personalizationGift, personalizationLetterCount]

    return personalizationText
  }



  displayProductId () {
    var product = this.findProperProductId();
    if (product == null)  {
      console.error("Product is null");
      return (
        <Button variant="primary" disabled>
        <FormattedMessage id="Summary.button-etsy-product-not-found"
                      defaultMessage="Product Variation Not Found On Etsy"
                      description="Label for button in summary if product on Etsy is not found"/>

        </Button>
      );
    }
    var priceRaw = this.originalProductPrice(product.offerings[0].price)
    priceRaw += this.props.withEngravingOnTheBack ? this.state.engravingPriceEur : 0;
    priceRaw += this.props.withStandPuzzle ? this.state.standPriceEur : 0;
    priceRaw += this.props.withGiftWrap ? this.state.giftWrapPriceEur : 0;
    // var priceString = "€"+ (priceRaw/100.0).toFixed(2) +" EUR"
    var priceString = this.props.intl.formatNumber(priceRaw/100.0, {style: 'currency', currency: product.offerings[0].price.original_currency_code})
    // var puzzleParamsDict = {
    //    listing_id : this.state.etsyListingID,
    //    selected_variations : "{\"513\":"+product.property_values[0].value_ids[0]+", \"514\":"+product.property_values[1].value_ids[0]+"}",
    //    personalization : this.summaryTextArray().join(', \n')
    //
    // }


    var cartParmsDict = {
      listing_id : this.state.etsyListingID,
      selected_variations : "{\"" + this.state.etsyColorSchemePropertyID + "\":"+product.property_values[0].value_ids[0]+","
                           + "\"" + this.state.etsyLetterCountPropertyID + "\":"+product.property_values[1].value_ids[0]+"}",
      personalization : this.summaryTextArray().join(', \n'),
    }

    if (this.props.withEngravingOnTheBack) {
      cartParmsDict['engraving_listing_id'] = this.state.etsyEngravingListingID
      cartParmsDict['engraving_personalization'] = this.props.textToEngrave
    }
    if (this.props.withStandPuzzle) {
      cartParmsDict['stand_listing_id'] = this.state.etsyStandListingID
    }
    if (this.props.withGiftWrap) {
      cartParmsDict['giftwrap_listing_id'] = this.state.etsyGiftWrapListingID
    }
    //
    // console.log(cartParmsDict)

    // console.log('array: ', $.param(cartParmsDict))
    //http://localhost:5000/guestCheckout
    let buttonText;
    if (this.state.isLoadingEtsyRedirect) {
      buttonText = 'Loading...'
    } else {
      buttonText = <span>
      <FormattedMessage id="Summary.button-etsy-product-found"
                    defaultMessage="Buy Now"
                    description="Buy now button label with price string after it"/> {priceString}
                    </span>
    }

    return (
      <Button
      variant="primary"
      href={"api/guestCheckout?" + $.param(cartParmsDict)}
      onClick={this.handleButtonClick}
      disabled={this.state.isLoadingEtsyRedirect}>
      {buttonText}
    </Button>

    );
  }

  componentDidMount () {
    // call our fetch function
    this.handleProductRequest()
  }



  render() {
    // if (this.props.currentStep !== 3) { // Prop: The current step
    //   return null
    // }
    var responseJSON = this.state.responseJSON;
    return (
      <div>
      <h1 className="header"><FormattedMessage id="Summary.header-preview"
                    defaultMessage="Preview"
                    description="Preview puzzle in summary step"/></h1>
        <ColoredTextPreview
        schemeNumber={this.props.selectedColorScheme}
        currentColorScheme={this.props.currentColorScheme}
        colors={this.props.colors}
        puzzleText={this.props.puzzleText}
        defaultWoodTypeString = {this.props.defaultWoodTypeString}
        oakWoodTypeString = {this.props.oakWoodTypeString}
        whiteHDFTypeString = {this.props.whiteHDFTypeString}
        woodName={this.props.woodName}/>
      <h1 className="header"><FormattedMessage id="Summary.header-summary"
                    defaultMessage="Order Summary"
                    description="Order summary heading in summary step"/></h1>

      <div id="summaryTextDiv" style={{whiteSpace: "pre-wrap"}}>{this.summaryTextArray().join('\n')}</div>

      {
        /* Logical shortcut for only displaying the
        button if the copy command exists */
        document.queryCommandSupported('copy') &&
        <div>
          <Button variant="primary" className="my-3"
            onClick={this.copyToClipboard}>
            <FormattedMessage id="Summary.button-copy-text"
                          defaultMessage="Copy Summary text to clipboard"
                          description="CTA to copy summary text to clipboard"/>

          </Button>
        </div>
      }

        {this.displayProductId()}
      </div>

    );
  }
}



class MainCointainer extends React.Component {
  constructor(props) {
    super(props);
    const {intl} = this.props;
    this.state = {
      puzzleText: intl.formatMessage({id: "MainCointainer.puzzleText", defaultMessage: "EMILY"}),
      placeholderTextArea: intl.formatMessage({id: "MainCointainer.placeholderTextArea", defaultMessage: "Enter the text for the puzzle"}),
      pegCheckboxLabel: intl.formatMessage({id: "MainCointainer.pegCheckboxLabel", defaultMessage: "With Pegs?"}),
      withPegs: true,
      woodName: intl.formatMessage({id: "MainCointainer.woodName", defaultMessage: "Plywood"}),
      withEngravingOnTheBack: false,
      textToEngrave: intl.formatMessage({id: "MainCointainer.textToEngrave", defaultMessage: "Your Text Here"}),
      withStandPuzzle: false,
      withGiftWrap: false,
      selectedColorScheme : "Andere",
      data: null,
      steps: [intl.formatMessage({id: "MainCointainer.steps.1", defaultMessage: "Text Preview"}),
              intl.formatMessage({id: "MainCointainer.steps.2", defaultMessage: "Options"}),
              intl.formatMessage({id: "MainCointainer.steps.3", defaultMessage: "Summary"})],
      defaultWoodTypeString: intl.formatMessage({id: "MainCointainer.defaultWoodTypeString", defaultMessage: "Plywood"}),
      oakWoodTypeString: intl.formatMessage({id: "MainCointainer.oakWoodTypeString", defaultMessage: "Sonoma Oak"}),
      whiteHDFTypeString: intl.formatMessage({id: "MainCointainer.whiteHDFTypeString", defaultMessage: "White HDF"}),
      colorSchemes: {
        1: [10, 1, 18, 8, 6],
        2: [16, 5, 18, 6],
        3: [1, 17, 18, 17],
        4: [1, 11, 6, 17, 11],
        5: [6, 18, 8],
        6: [15, 11, 18, 5, 17],
        7: [15, 3, 14, 13, 8],
        8: [3, 17, 11, 15, 1],
        9: [4, 17, 18],
        10: [12, 4, 11, 4],
        11: [11, 12, 14, 15],
        12: [3, 10, 3, 18],
        13: [13, 1, 10, 18, 17],
        14: [5, 8, 4, 8, 18],
        15: [3, 8, 15],
        16: [4, 10, 16, 5],
        17: [18, 10, 16, 3],
        18: [8, 18, 10, 1],
        Andere: [1]
      },
      colors: {
        1: "#C3D8ED",
        3: "#191A1B",
        4: "#F8BECF",
        5: "#E6D9CA",
        6: "#A48A80",
        7: "#61472C",
        8: "#D7B48B",
        10: "#D3D3CF",
        11: "#F9EBD7",
        12: "#00544b",
        13: "#21476b",
        14: "#FFFCE8",
        15: "#AE1B34",
        16: "#F1EAE8",
        17: "#aecec3",
        18: "#F6F5F2"
      },
    }
    this.handlePegCheckboxSubmit = this.handlePegCheckboxSubmit.bind(this);
    this.handlePuzzleTextAreaChange = this.handlePuzzleTextAreaChange.bind(this);
    this.handlePuzzleTextAreaSubmit = this.handlePuzzleTextAreaSubmit.bind(this);
    this.handleColorSchemeSelect = this.handleColorSchemeSelect.bind(this);
    this.handleColoredBackgroundChange = this.handleColoredBackgroundChange.bind(this);
    this.handleEngravingOnTheBackChange = this.handleEngravingOnTheBackChange.bind(this);
    this.handleEngraveTextChange = this.handleEngraveTextChange.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
    this.handleColorSchemeSelectClick = this.handleColorSchemeSelectClick.bind(this);
  }
  handlePegCheckboxSubmit () {
    // alert('A name was submitted: ' + this.state.value);
    //   // event.preventDefault();
  }
  handleColoredBackgroundChange (event) {
    this.setState({
      woodName: event.target.id

    });
  }
  handleEngravingOnTheBackChange (event) {
    if(event.target.name) {
      this.setState({
        withEngravingOnTheBack: !this.state.withEngravingOnTheBack
      });
    }
  }
  handleEngravingOnTheBackChange (event) {
    if(event.target.name) {
      this.setState({
        withEngravingOnTheBack: !this.state.withEngravingOnTheBack
      });
    }
  }


  handleOptionsChange (event) {
    console.log("Options Changed");
    if(event.target.name == "giftWrapGroup") {
      this.setState({
        withGiftWrap: !this.state.withGiftWrap,
      });
    } else if(event.target.name == "puzzleStandGroup") {
      this.setState({
        withStandPuzzle: !this.state.withStandPuzzle,
      });
    } else if(event.target.name == "pegsGroup") {
      this.setState({
        withPegs: !this.state.withPegs,
      });
    }

  }
  handleEngraveTextChange (event) {
    // console.log(event.target);
    this.setState({
      textToEngrave: event.target.value
    });
  }
  handlePuzzleTextAreaChange (event) {
    const input = event.target;

    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState(
      {puzzleText: input.value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  handlePuzzleTextAreaSubmit(event) {
    // alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }
  handleColorSchemeSelect(event) {
    if (event.target.id == "formSelect"){
      this.setState({
        selectedColorScheme: event.target.value
      });
    } else if (event.target.id.includes("formCustomSelect")) {
      // get value of each formSelect#
      var splitString = event.target.id.split("formCustomSelect")
      var characterIndex = parseInt(splitString[splitString.length -1]);
      var newOptions = this.state.colorSchemes["Andere"]
      if (this.state.colorSchemes["Andere"].length !== this.state.puzzleText.length) {
        var newOptions = Array(this.state.puzzleText.length).fill(1);
        for (var i = 0; i < newOptions.length && i < this.state.colorSchemes["Andere"].length; i++) {
          newOptions[i] = this.state.colorSchemes["Andere"][i];
        }
      }
      newOptions[characterIndex] = parseInt(event.target.value);
      var newColorSchemes = this.state.colorSchemes;
      newColorSchemes["Andere"] = newOptions;
      // this.setState({
      //   colorSchemes: newColorSchemes
      // });
      this.setState(prevState => ({
          colorSchemes: {                   // object that we want to update
              ...prevState.colorSchemes,    // keep all other key-value pairs
              "Andere": newOptions    // update the value of specific key
          }
      }))
    }
  }

  handleColorSchemeSelectClick (event) {
    if (event.currentTarget.id ){
      this.setState({
        selectedColorScheme: event.currentTarget.id
      });
      // this._next();
      document.getElementById('nextButton').click()
    } else {
      console.log("Unknown Color Scheme")
    }
  }

  render() {

    const currLocale = this.props.intl.locale;
    var logoImage = ''
    console.log("current locale:", currLocale)
    switch (currLocale) {
      case 'de':
        logoImage = wunderholzLogo
        break
      case 'en':
        logoImage = beechWoodLogo
        break
      default:
        console.log("current locale2:", currLocale)
        logoImage = wunderholzLogo
    }

    return (
      <BrowserRouter>
      <Container className="mainContainer">
      <Navbar bg="light" variant="light">
        <Navbar.Brand href="#">
          <img
            alt={this.props.intl.formatMessage({id: "Navbar.brand.alt", defaultMessage: "BeechwoodStory"})}
            src={logoImage}
            height="30"
            className="d-inline-block align-top"
          />{' '}
          <FormattedMessage id="NavBar.brand.text" defaultMessage="BeechwoodStory"/>
        </Navbar.Brand>
      </Navbar>
        <div className="row pad-t">
          <div className="col-xs-6 col-xs-offset-3">
          <Switch>
          <Route exact path="/admin" render={() => (
            <InputTable />
          )}>
            <InputTable />
          </Route>
          <Route
              path="/"
              render={({ history }) => (
                <Wizard history={history}>
                  <Steps>
                    <Step id={this.props.intl.formatMessage({id: "Route.Step1", defaultMessage: "colors"})}>
                      {/*Step 1*/}

                        <Container className="editContainer">
                            <Form>
                              <PuzzleTextArea
                              handleChange={this.handlePuzzleTextAreaChange}
                              handleSubmit={this.handlePuzzleTextAreaSubmit}
                              puzzleText={this.state.puzzleText}
                              placeholderTextArea={this.state.placeholderTextArea}/>

                              {/*
                              <ColorSchemeSelector
                              colors={this.state.colors}
                              puzzleText={this.state.puzzleText}
                              colorSchemes={this.state.colorSchemes}
                              handleChange={this.handleColorSchemeSelect}
                              selectedColorScheme={this.state.selectedColorScheme}/>
                              */}
                            </Form>
                        </Container>

                        <Container className="previewContainer">
                          <TextPreview
                          puzzleText={this.state.puzzleText}
                          colors={this.state.colors}
                          colorSchemes={this.state.colorSchemes}
                          selectedColorScheme={this.state.colorSchemes[this.state.selectedColorScheme]}
                          defaultWoodTypeString = {this.state.defaultWoodTypeString}
                          oakWoodTypeString = {this.state.oakWoodTypeString}
                          whiteHDFTypeString = {this.state.whiteHDFTypeString}
                          woodName={this.state.woodName}
                          steps={this.state.steps}
                          handleButtonClick={this.handleColorSchemeSelectClick}
                          handleChange={this.handleColorSchemeSelect}/>

                        </Container>
                    </Step>
                    <Step id={this.props.intl.formatMessage({id: "Route.Step2", defaultMessage: "options"})}>
                      {/*Step 2*/}
                      <Container className="optionsContainer">
                        <Form>
                          <OptionsComponent
                          handleColoredBackgroundChange={this.handleColoredBackgroundChange}
                          handleEngravingOnTheBackChange={this.handleEngravingOnTheBackChange}
                          handleEngraveTextChange={this.handleEngraveTextChange}
                          handleOptionsChange={this.handleOptionsChange}
                          withPegs={this.state.withPegs}
                          defaultWoodTypeString = {this.state.defaultWoodTypeString}
                          oakWoodTypeString = {this.state.oakWoodTypeString}
                          whiteHDFTypeString = {this.state.whiteHDFTypeString}
                          woodName={this.state.woodName}
                          withEngravingOnTheBack={this.state.withEngravingOnTheBack}
                          textToEngrave={this.state.textToEngrave}
                          withStandPuzzle={this.state.withStandPuzzle}
                          withGiftWrap={this.state.withGiftWrap}
                          selectedColorScheme={this.state.selectedColorScheme}
                          currentColorScheme={this.state.colorSchemes[this.state.selectedColorScheme]}
                          colors={this.state.colors}
                          puzzleText={this.state.puzzleText}
                          />
                        </Form>
                      </Container>
                    </Step>
                    <Step id={this.props.intl.formatMessage({id: "Route.Step3", defaultMessage: "overview"})}>
                      {/*Step 3*/}
                      <Container className="svgContainer">
                        <SVGPreview
                        puzzleText={this.state.puzzleText}/>
                      </Container>
                      <Container className="summaryContainer">

                        <Summary
                        intl={this.props.intl}
                        puzzleText={this.state.puzzleText}
                        withPegs={this.state.withPegs}
                        defaultWoodTypeString = {this.state.defaultWoodTypeString}
                        oakWoodTypeString = {this.state.oakWoodTypeString}
                        whiteHDFTypeString = {this.state.whiteHDFTypeString}
                        woodName={this.state.woodName}
                        withEngravingOnTheBack={this.state.withEngravingOnTheBack}
                        textToEngrave={this.state.textToEngrave}
                        withStandPuzzle={this.state.withStandPuzzle}
                        withGiftWrap={this.state.withGiftWrap}
                        selectedColorScheme={this.state.selectedColorScheme}
                        colorSchemes={this.state.colorSchemes}
                        currentColorScheme={this.state.colorSchemes[this.state.selectedColorScheme]}
                        colors={this.state.colors}
                        />
                      </Container>
                    </Step>
                  </Steps>
                  <Navigation />
                </Wizard>
              )}
            />
          </Switch>

          </div>
        </div>
        </Container>
      </BrowserRouter>
    );
  }
}

const App = () => {
  const intl = useIntl()
  return (
      <MainCointainer
      intl={intl}/>
  );
}

export default App;
