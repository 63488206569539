import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import {injectIntl} from 'react-intl';

class ColoredTextPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textBackgroundColor: "#000000"
    }
    this.applyColorSchemeToText = this.applyColorSchemeToText.bind(this);
  }

  applyColorSchemeToText(schemeNumber) {
    var colorChars = [];
    var spaceShift = 0;
    for (var i = 0; i < this.props.puzzleText.length; i++) {
      if (this.props.puzzleText.charAt(i) == ' ' && !isNaN(parseInt(schemeNumber))) {
        spaceShift++;
        colorChars.push(
          <span> </span>
        )
      } else {
        var colorListItem = (i-spaceShift) % this.props.currentColorScheme.length;
        colorChars.push(
          <span style={{color: this.props.colors[this.props.currentColorScheme[colorListItem]]}}>
            {this.props.puzzleText.charAt(i)}
          </span>
        )
      }

    }
    return colorChars
  }

  render () {

    // const backgroundType = this.props.withSonomaOak ? 'coloredBackground' : 'normalBackground';
    var backgroundType = '';
    switch (this.props.woodName) {
      case this.props.oakWoodTypeString:
        backgroundType = 'oakBackground';
        break;
      case this.props.whiteHDFTypeString:
        backgroundType = 'whiteBackground';
        break;
      default:
        backgroundType = 'plywoodBackground';
    }
    const classes = 'colorSchemePreview' + ' ' + backgroundType
    return (
        <div className={classes}>
          {this.applyColorSchemeToText(this.props.schemeNumber)}
        </div>
    )
  }
}

class TextPreviewIntl extends React.Component {
  constructor(props) {
    super(props);
    const {intl} = this.props;
    // puzzleText
    // colors
    // colorSchemes
    // selectedColorScheme
    this.state = {
      colorBlocks: intl.formatMessage({id: "TextPreview.colorBlocks", defaultMessage: "''"}),
      displayPuzzleText: intl.formatMessage({id: "TextPreview.displayPuzzleText", defaultMessage: "''"}),
      colorSchemeString: intl.formatMessage({id: "TextPreview.colorSchemeString", defaultMessage: "Color Scheme"}),
      colorSchemeButtonString: intl.formatMessage({id: "TextPreview.colorSchemeButtonString", defaultMessage: "Choose this color scheme"}),
      colorForTheLetterString: intl.formatMessage({id: "TextPreview.colorForTheLetterString", defaultMessage: "Color for the letter"}),
    }
    this.colorSchemeDiv = this.colorSchemeDiv.bind(this);
    this.otherColorSchemeDiv = this.otherColorSchemeDiv.bind(this);
    this.generateSelectFormForEachCharacter = this.generateSelectFormForEachCharacter.bind(this);
    this.generateSelectForm = this.generateSelectForm.bind(this);
  }

  generateSelectForm(selectValue) {
    return (
      <Form.Control
      as="select"
      value={selectValue}
      onChange={this.props.handleChange.bind(this)}>
        {Object.keys(this.props.colors).map(function(key, index) {
                if(key !== "Other") {
                return (
                  <option key={key}>{key}</option>
                );
              }
              })}
      </Form.Control>
    );
  }

  generateSelectFormForEachCharacter() {
    var selectFormArray = []
    var optionsArray = this.props.colorSchemes["Andere"];

    selectFormArray.push(
      <div class="d-flex flex-wrap" style={{marginTop: "10px", marginBottom: "10px"}}>
      {Object.entries(this.props.colors).map(function([key, value], index) {
        if(key !== "Other") {
        return (
          <div class="colorSelectBox" style={{backgroundColor: value}}>{key}</div>
        );
      }
      })}
      </div>
    )

    for (var i = 0; i<this.props.puzzleText.length; i++) {
      var selectValue = optionsArray[i]
      selectFormArray.push(
        <Form.Group key={"formCustomSelect"+i.toString()} controlId={"formCustomSelect"+i.toString()}>
        <Row lg={3} xs={12}>
          <Form.Label>
             {this.state.colorForTheLetterString + " " + this.props.puzzleText.charAt(i)}:
          </Form.Label>
          {this.generateSelectForm(selectValue)}
          </Row>
        </Form.Group>
      );
      // selectValues from Other list
    }
    return selectFormArray;
  }

  otherColorSchemeDiv(schemeNumber) {
    return (
      <Row id="colorSchemeDiv">
        <Button variant="light" id={schemeNumber} block="True" >
          <Row>
            <Col lg={12}>
              <ColoredTextPreview
              schemeNumber={schemeNumber}
              currentColorScheme={this.props.colorSchemes[schemeNumber]}
              colors={this.props.colors}
              puzzleText={this.props.puzzleText}
              woodName={this.props.woodName}
              defaultWoodTypeString = {this.props.defaultWoodTypeString}
              oakWoodTypeString = {this.props.oakWoodTypeString}
              whiteHDFTypeString = {this.props.whiteHDFTypeString}/>
            </Col>
            <Col lg={12}>
              {this.generateSelectFormForEachCharacter()}
            </Col>
            <Col md="auto">
              <span style={{lineHeight: "38px"}}>
              {this.state.colorSchemeString} {this.props.intl.formatMessage({id: "Summary.OtherColorSchemeName2", defaultMessage: "Other"})}
              </span>
            </Col>
            <Col>
              <Button
                className="float-md-right"
                type="button"
                id={schemeNumber}
                onClick={this.props.handleButtonClick}>
                {this.state.colorSchemeButtonString}
              </Button>
            </Col>

          </Row>
        </Button>
      </Row>
    )
  }

  colorSchemeDiv(schemeNumber) {
    return (
      <Row id="colorSchemeDiv">
      <Button variant="light" id={schemeNumber} block="True" onClick={this.props.handleButtonClick}>
        <Row>
            <Col lg={12}>
              <ColoredTextPreview
              schemeNumber={schemeNumber}
              currentColorScheme={this.props.colorSchemes[schemeNumber]}
              colors={this.props.colors}
              puzzleText={this.props.puzzleText}
              woodName={this.props.woodName}
              defaultWoodTypeString = {this.props.defaultWoodTypeString}
              oakWoodTypeString = {this.props.oakWoodTypeString}
              whiteHDFTypeString = {this.props.whiteHDFTypeString}/>
            </Col>


            <Col md="auto">
              <span style={{lineHeight: "38px"}}>{this.state.colorSchemeString} {schemeNumber}</span>
            </Col>
            <Col>
              <Button
              type="button"
              className="float-md-right">
              {this.state.colorSchemeButtonString}
              </Button>
            </Col>
            </Row>
          </Button>
      </Row>
    )
  }
    render() {
      // if (this.props.currentStep !== 1) { // Prop: The current step
      //   return null
      // }

      var colorSchemesDivs = []
      for (const colorSchemeKey in this.props.colorSchemes) {
        if (isNaN(parseInt(colorSchemeKey))) {
          colorSchemesDivs.push(
            this.otherColorSchemeDiv(colorSchemeKey)
          )
        } else {
          colorSchemesDivs.push(
            this.colorSchemeDiv(colorSchemeKey)
          )
        }

      }

      return (
        <div>

        {/*
          <p>Color Scheme Pattern: </p>
          {colorBlocksTemp}
        <div className="textPreview">{colorChars}</div>
        */}
        <Container>
          {colorSchemesDivs}
        </Container>
        </div>
      );
    }
}

const TextPreview = injectIntl(TextPreviewIntl);

export {
  TextPreview,
  ColoredTextPreview
};
