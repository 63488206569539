// takes a list of names and color shemes as input and outputs which letters match which color

const colorSchemes = {
        1: [10, 1, 18, 8, 6],
        2: [16, 5, 18, 6],
        3: [1, 17, 18, 17],
        4: [1, 11, 6, 17, 11],
        5: [6, 18, 8],
        6: [15, 11, 18, 5, 17],
        7: [15, 3, 14, 13, 8],
        8: [3, 17, 11, 15, 1],
        9: [4, 17, 18],
        10: [12, 4, 11, 4],
        11: [11, 12, 14, 15],
        12: [3, 10, 3, 18],
        13: [13, 1, 10, 18, 17],
        14: [5, 8, 4, 8, 18],
        15: [3, 8, 15],
        16: [4, 10, 16, 5],
        17: [18, 10, 16, 3],
        18: [8, 18, 10, 1],
        Andere: [1]
      };
const colors = {
        1: "#C3D8ED",
        3: "#191A1B",
        4: "#F8BECF",
        5: "#E6D9CA",
        6: "#A48A80",
        7: "#61472C",
        8: "#D7B48B",
        10: "#D3D3CF",
        11: "#F9EBD7",
        12: "#00544b",
        13: "#21476b",
        14: "#FFFCE8",
        15: "#AE1B34",
        16: "#F1EAE8",
        17: "#aecec3",
        18: "#F6F5F2"
      };
const colorNames = {
        1: "Aqua",
        3: "Black",
        4: "Candy pink",
        5: "Cappuccino",
        6: "Frappe",
        7: "Hot chocolate",
        8: "Light brown",
        10: "Light grey",
        11: "Melon",
        12: "Monstera",
        13: "Nile",
        14: "Panna cotta",
        15: "Red deluxe",
        16: "Vanilla cream",
        17: "Wave",
        18: "White"
      };


var letterColors = {
        '1': [],
        '3': [],
        '4': [],
        '5': [],
        '6': [],
        '7': [],
        '8': [],
        '10': [],
        '11': [],
        '12': [],
        '13': [],
        '14': [],
        '15': [],
        '16': [],
        '17': [],
        '18': []
      };

var inputs = [{
'name': 'Maisie',
'scheme': '16',
'colors': '',
'finalColors': [],
},
{
'name': 'HANNA',
'scheme': 'Other',
'colors': [4,18,17,17,18],
'finalColors': [],
},
{
'name': 'BEN',
'scheme': 'Other',
'colors': [4,18,17,16,18],
'finalColors': [],
}];

export{ inputs, letterColors, colors, colorSchemes, colorNames }


// for (var input of inputs) {
// 	input.name = Array.from(input.name)
//
//   if (input.scheme != 'Other') {
//   		input.colors = colorSchemes[parseInt(input.scheme)]
//   }
//   // extend finalcolors to match length of name
  // if (input.name.length < input.colors.length) {
  // 	input.finalColors = input.colors.slice(0, input.name.length)
  // } else {
  // 	var newArray = Array(input.name.length)
  //   for (let i=0; i<input.name.length; i++) {
  //   	let colorIndex = i % input.colors.length
  //     newArray[i] = input.colors[colorIndex]
  //   }
//     input.finalColors = newArray
//   }
// }
//
// console.log(inputs)
//
// for (var input of inputs) {
// 	// go through each letter in the name
//   for (let i = 0; i<input.name.length; i++) {
//   	const letter = input.name[i]
//     const color = input.finalColors[i]
//     letterColors[color].push(letter)
//   }
//   // add the letter to the corespongin color
//   console.log(letterColors)
// }
