import React from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import {TextPreview, ColoredTextPreview} from './colorSchemeGrid.js'
import {injectIntl, FormattedMessage, FormattedHTMLMessage} from 'react-intl';

window.$ = $;

class PuzzleTextAreaIntl extends React.Component {
  render() {
    // if (this.props.currentStep !== 1) { // Prop: The current step
    //   return null
    // }
    return (
      <Form onSubmit={this.props.handleSubmit.bind(this)}>
      <Form.Group controlId="formTextArea">
        <Form.Label><FormattedMessage id="puzzleTextArea.form-label-text"
                      defaultMessage="Text"
                      description="Label for control form to input text"/></Form.Label>
        <Form.Control
          as='input'
          maxLength="12"
          style={{textTransform: "uppercase"}}
          placeholder={this.props.placeholderTextArea}
          value={this.props.puzzleText}
          onChange={this.props.handleChange.bind(this)}
          />
          <Form.Text className="text-muted">
          <FormattedMessage id="puzzleTextArea.form-muted-text"
                        defaultMessage="Max length is 12 letters. All text in the puzzle will be in a single line."
                        description="Muted text under control form to input text"/>
                        </Form.Text>
      </Form.Group>
      </Form>
    );
  }
}

class ColorSchemeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustomColorInput: false
    }
    this.setShowColorInput = this.setShowColorInput.bind(this);
    this.generateSelectForm = this.generateSelectForm.bind(this);
    this.generateSelectFormForEachCharacter = this.generateSelectFormForEachCharacter.bind(this);
  }

  setShowColorInput(boolShow) {
    console.log(boolShow)
    this.setState({
      showCustomColorInput: boolShow
    });
  }

  componentDidMount () {
    if (this.props.selectedColorScheme === "Andere") {
      this.setShowColorInput(true);
    } else {
      this.setShowColorInput(false);
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedColorScheme !== this.props.selectedColorScheme) {
      if (this.props.selectedColorScheme === "Andere") {
        this.setShowColorInput(true);
      } else {
        this.setShowColorInput(false);
      }
    }
  }

  generateSelectForm(selectValue) {
    return (
      <Form.Control
      as="select"
      value={selectValue}
      onChange={this.props.handleChange.bind(this)}>
        {Object.keys(this.props.colors).map(function(key, index) {
                if(key !== "Andere") {
                return (
                  <option key={key}>{key}</option>
                );
              }
              })}
      </Form.Control>
    );
  }

  generateSelectFormForEachCharacter() {
    var selectFormArray = []

    var optionsArray = this.props.colorSchemes["Andere"];


    for (var i = 0; i<this.props.puzzleText.length; i++) {
      var selectValue = optionsArray[i]
      selectFormArray.push(
        <Form.Group as={Row} key={"formCustomSelect"+i.toString()} controlId={"formCustomSelect"+i.toString()}>
          <Form.Label column sm="2">
            Color for the letter {this.props.puzzleText.charAt(i)}:
          </Form.Label>
          <Col sm="10">
            {this.generateSelectForm(selectValue)}
          </Col>
        </Form.Group>
      );
      // selectValues from Other list
    }
    return selectFormArray;
  }

  render() {
    var colorDict = this.props.colors;
    // var colorSchemesDict = this.props.colorSchemes;

    var colorList = []
    for (let [key, value] of Object.entries(colorDict)) {
      console.log(key, value)
      var colorBlocks = <Col key={key} style={{background: value, width:100, height:100, display: 'inline-block'}}>{key}</Col>;
      colorList.push(colorBlocks);
    }
    colorList.push(<div></div>);


    // if (this.props.currentStep !== 1) { // Prop: The current step
    //   return null
    // }

    if (this.state.showCustomColorInput) {
      var customInput = this.generateSelectFormForEachCharacter();
    } else {
      var customInput = ''
    }

    return (
      <div>
      <h1>ColorList</h1>
      {colorList}
      <Form.Group controlId="formSelect">
        <Form.Label><FormattedMessage id="ColorSchemeSelector.form-label-text"
                      defaultMessage="Color Scheme Selector"
                      description="Label for ColorSchemeSelector form"/></Form.Label>
        <Form.Control
          as="select"
          value={this.props.selectedColorScheme}
          onChange={this.props.handleChange.bind(this)}>
          {Object.keys(this.props.colorSchemes).map(function(key, index) {
                  return (
                    <option key={key}>{key}</option>
                  );
                })}
        </Form.Control>
      </Form.Group>
      {customInput}
      </div>
    );
  }
}

class OptionsComponentIntl extends React.Component {
  constructor(props) {
    super(props);
    const {intl} = this.props;
    this.state = {
      withoutEngravingOnBackString: intl.formatMessage({id: "OptionsComponent.withoutEngravingOnBackString", defaultMessage: "Without Engraving on the Back"}),
      withEngravingOnBackString: intl.formatMessage({id: "OptionsComponent.withEngravingOnBackString", defaultMessage: "With Engraving on the Back"}),
      withoutPuzzleStandString: intl.formatMessage({id: "OptionsComponent.withoutPuzzleStandString", defaultMessage: "Without a Puzzle Stand"}),
      withPuzzleStandString: intl.formatMessage({id: "OptionsComponent.withPuzzleStandString", defaultMessage: "With a Puzzle Stand"}),
      withoutGiftWrapString: intl.formatMessage({id: "OptionsComponent.withoutGiftWrapString", defaultMessage: "Without Gift Wrap"}),
      withGiftWrapString: intl.formatMessage({id: "OptionsComponent.withGiftWrapString", defaultMessage: "With Gift Wrap"}),
    }
  }

  render() {
    // if (this.props.currentStep !== 2) { // Prop: The current step
    //   return null
    // }
    return (
      <div>
      <h1 className="header"><FormattedMessage id="OptionsComponent.header-preview"
                    defaultMessage="Preview"
                    description="Header for Preview in Options screen"/></h1>
        <ColoredTextPreview
        schemeNumber={this.props.selectedColorScheme}
        currentColorScheme={this.props.currentColorScheme}
        colors={this.props.colors}
        puzzleText={this.props.puzzleText}
        woodName={this.props.woodName}
        defaultWoodTypeString = {this.props.defaultWoodTypeString}
        oakWoodTypeString = {this.props.oakWoodTypeString}
        whiteHDFTypeString = {this.props.whiteHDFTypeString}/>


      <h1 className="header"><FormattedMessage id="OptionsComponent.header-options"
                    defaultMessage="Options"
                    description="Header for Options in Options screen"/></h1>

      <Form>
      <Form.Group
      onChange={this.props.handleColoredBackgroundChange}
      >

      <Form.Label><FormattedMessage id="OptionsComponent.form-label-woodType"
                    defaultMessage="Wood type"
                    description="Label for Wood Type Form"/></Form.Label>

      <Form.Check
        type="radio"
        id={ this.props.defaultWoodTypeString }
        name="coloredBackgroundGroup"
        label={ this.props.defaultWoodTypeString }
        defaultChecked={this.props.woodName === this.props.defaultWoodTypeString}
      />
      <Form.Check
        type="radio"
        id={ this.props.oakWoodTypeString }
        name="coloredBackgroundGroup"
        label={ this.props.oakWoodTypeString }
        defaultChecked={this.props.woodName === this.props.oakWoodTypeString}
      />
      <Form.Check
        type="radio"
        id={ this.props.whiteHDFTypeString }
        name="coloredBackgroundGroup"
        label={ this.props.whiteHDFTypeString }
        defaultChecked={this.props.woodName === this.props.whiteHDFTypeString}
      />
      </Form.Group>

      <Form.Group
      onChange={this.props.handleEngravingOnTheBackChange}>
      <Form.Label><FormattedMessage id="OptionsComponent.form-label-backEngraving"
                    defaultMessage="Engraving on the Back"
                    description="Label for Colored Background Form"/>
                    </Form.Label>
      <Form.Check
        type="radio"
        id="engraveBackNo"
        name="engraveBackGroup"
        label={ this.state.withoutEngravingOnBackString }
        defaultChecked={!this.props.withEngravingOnTheBack}
      />
      <Form.Check
        type="radio"
        id="engraveBackYes"
        name="engraveBackGroup"
        label={ this.state.withEngravingOnBackString }
        defaultChecked={this.props.withEngravingOnTheBack}
      />

      <Form.Control
        as="textarea"
        maxLength="200"
        placeholder={this.props.placeholderEngraveText}
        value={this.props.textToEngrave}
        onChange={this.props.handleEngraveTextChange.bind(this)}
        disabled={!this.props.withEngravingOnTheBack}
        />
      </Form.Group>

      <Form.Group
      onChange={this.props.handleOptionsChange}>

      <Form.Label><FormattedMessage id="OptionsComponent.form-label-puzzle"
                    defaultMessage="Puzzle on a Stand"
                    description="Label for Puzzle on a stand option in Option Form"/></Form.Label>

      <Form.Check
        type="radio"
        id="puzzleStandNo"
        name="puzzleStandGroup"
        label={ this.state.withoutPuzzleStandString }
        defaultChecked={!this.props.withStandPuzzle}
      />
      <Form.Check
        type="radio"
        id="puzzleStandYes"
        name="puzzleStandGroup"
        label={ this.state.withPuzzleStandString }
        defaultChecked={this.props.withStandPuzzle}
      />
      </Form.Group>

      <Form.Group
      onChange={this.props.handleOptionsChange}>

      <Form.Label><FormattedMessage id="OptionsComponent.form-label-gift-wrap"
                    defaultMessage="Gift Wrap"
                    description="Label for Gift wrap option in Option Form"/></Form.Label>

      <Form.Check
        type="radio"
        id="giftWrapNo"
        name="giftWrapGroup"
        label={ this.state.withoutGiftWrapString }
        defaultChecked={!this.props.withGiftWrap}
      />
      <Form.Check
        type="radio"
        id="giftWrapYes"
        name="giftWrapGroup"
        label={ this.state.withGiftWrapString }
        defaultChecked={this.props.withGiftWrap}
      />
      </Form.Group>
      </Form>
      </div>
    );
  }
}

const PuzzleTextArea = injectIntl(PuzzleTextAreaIntl);
const OptionsComponent = injectIntl(OptionsComponentIntl);

export {
  PuzzleTextArea,
  ColorSchemeSelector,
  OptionsComponent
};
