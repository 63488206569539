import React from 'react';
import {colorSchemes, letterColors, colorNames} from './colorSchemesDicts'

export default class DynamicTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      scheme: "",
      colors: "",
      items: [],
      letterColorsDict: {}
    }
  }

  updateMessage(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  // handleListClick() {
  //   console.log('Create list')
  //
  //   this.setState(prevState => {
  //     let newLetterColorsDict = Object.assign({}, prevState.letterColorsDict);  // creating copy of state variable jasper
  //     for (var item of this.state.items) {
  //       // go through each letter in the name
  //       for (let i = 0; i<item[0].length; i++) {
  //         const letter = item[0][i]
  //         const color = item[3][i]
  //         newLetterColorsDict[color].push(letter)
  //       }
  //       // add the letter to the corespongin color
  //     }       // update the name property, assign a new value
  //     return { newLetterColorsDict };                                 // return new object jasper object
  //   })
  // }

  handleClick(event) {
    event.preventDefault()
    var items = this.state.items;
    var colors = this.state.colors.split(/[ ,]+/).map(Number);;
    var schemeNumber = parseInt(this.state.scheme);
    var finalColors = []
    if (!(isNaN(schemeNumber))) {
      colors = colorSchemes[this.state.scheme]
    }
    if(this.state.colors.length < 1 &&  colors.length <1) {
      this.setState({
        name: "",
        scheme: "",
        colors: "",
      });
      return
    }

    if (this.state.name.length < colors.length) {
      finalColors = colors.slice(0, this.state.name.length)
    } else {
      var newArray = Array(this.state.name.length)
      for (let i=0; i<this.state.name.length; i++) {
        let colorIndex = i % colors.length
        newArray[i] = colors[colorIndex]
      }
      finalColors = newArray
    }

    items.push([this.state.name.toUpperCase(), this.state.scheme, colors, finalColors]);

    this.setState({
      items: items,
      name: "",
      scheme: "",
      colors: "",
    });
  }

  handleItemChanged(i, event) {
    var items = this.state.items;
    items[i]  = event.target.value;

    this.setState({
      items: items
    });
  }

  handleItemDeleted(i) {
    var items = this.state.items;

    items.splice(i, 1);

    this.setState({
      items: items
    });
  }

  renderRows() {
    var context = this;

    return  this.state.items.map(function(o, i) {
              return (
                <tr key={"item-" + i}>
                <td>
                {o[0]}
                </td>
                <td>
                {o[1]}
                </td>
                <td>
                {o[2].join(', ')}
                </td>
                <td>
                {o[3].join(', ')}
                </td>
                  <td>
                    <button
                      onClick={context.handleItemDeleted.bind(context, i)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            });
  }

  renderLettersforColors() {
    var letterColorsDict = JSON.parse(JSON.stringify(letterColors))
    for (var item of this.state.items) {
    	// go through each letter in the name
      for (let i = 0; i<item[0].length; i++) {
      	const letter = item[0][i]
        const color = item[3][i]
        console.log('color:', typeof(color))
        letterColorsDict[color].push(letter)
      }
    }
      // add the letter to the corespongin color
    console.log(letterColors)
  return Object.keys(letterColorsDict).map(function(key, index) {
        if (letterColorsDict[key].length != 0) {
          return (
            <tr key={"color-item-" + key}>
              <td>
              {key}
              </td>
              <td>
              {colorNames[key]}
              </td>
              <td>
              {letterColorsDict[key].join('')}
              </td>
            </tr>
          )
        }
    })
}

  render() {
    return (
      <div>
      <form>
        <label> Name: </label>
          <input
            type="text"
            name="name"
            value={this.state.name}
            onChange={this.updateMessage.bind(this)}
          />

          <br />
          <label>Scheme: </label>
          <input
            type="text"
            name="scheme"
            value={this.state.scheme}
            onChange={this.updateMessage.bind(this)}
          />
          <br />
          <label>Color: </label>
          <input
            type="text"
            name="colors"
            value={this.state.colors}
            onChange={this.updateMessage.bind(this)}
          />

      </form>
      <button
        type="submit"
        value="Add Item"
        onClick={this.handleClick.bind(this)}
      >
      Add Item
      </button>
        <hr/>
        <table className="nameTable">
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Color Scheme
              </th>
              <th>
                Colors
              </th>
              <th>
                Final Colors
              </th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
        <hr/>


        <br />

        <div>
          <h5>Letters in Each Color:</h5>
          <table className="nameTable">
            <thead>
              <tr>
                <th>
                  Number
                </th>
                <th>
                  Color Name
                </th>
                <th>
                  Letters
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderLettersforColors()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
